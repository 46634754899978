import {handleFetchResponseErrors} from '../../fetchUtils';
import {getDefaultPersistedLifeToStartWith} from '../../PersistedLife';
import StorageFileService from '../../StorageFileService';
import envConfig from '../../../../envConfig';

const STORAGE_FILE_NAME = envConfig.storageFile;

const googleDriveService: StorageFileService = {
  getOrCreateStorageFile,
  loadStorageFileContent,
  saveStorageFile
};

export default googleDriveService;

/**
 * Makes sure that the storage file exists.
 * Returns the fileId of the already existing file, or the newly created file.
 */
async function getOrCreateStorageFile(accessToken: string): Promise<string> {
  const fileId = await _searchExistingStorageFile(accessToken);
  if (fileId) {
    // console.log('storage file exists, no need to create');
    return fileId;
  } else {
    // console.log('need to create storage file', STORAGE_FILE_NAME);
    return _createStorageFile(accessToken);
  }
}

/**
 * searches for an existing, non-trashed file with the matching STORAGE_FILE_NAME.
 * Returned promise resolves to the fileId or to undefined, if no matching file was found.
 *
 * @private
 */
async function _searchExistingStorageFile(accessToken: string): Promise<string | undefined> {
  console.debug(`looking for storage file "${STORAGE_FILE_NAME}"`);

  const q = `name='${STORAGE_FILE_NAME}'`;
  const fields = `files(name,id,trashed)`;
  let res = await fetch(
    `https://www.googleapis.com/drive/v3/files?fields=${fields}&q=${encodeURI(q)}`,
    {
      method: 'GET',
      headers: new Headers({Authorization: 'Bearer ' + accessToken})
    }
  );
  res = await handleFetchResponseErrors(res);
  const val = await res.json();
  if (val && val.files && val.files.length) {
    const matchingFile = val.files.find((f: any) => f.name === STORAGE_FILE_NAME && !f.trashed); // TODO: replace "any"?
    if (matchingFile) {
      return matchingFile.id;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

/**
 * https://developers.google.com/drive/api/v3/reference/files/create
 * If successful, the HTTP response contains a "File" instance, see  https://developers.google.com/drive/api/reference/rest/v3/files#File
 *
 * The returned Promise resolves to the id of the created file
 *
 * @private
 */
async function _createStorageFile(accessToken: string): Promise<string> {
  const fileContent = JSON.stringify(getDefaultPersistedLifeToStartWith());
  const file = new Blob([fileContent], {type: 'text/plain'});
  const metadata = {
    name: STORAGE_FILE_NAME,
    mimeType: 'text/plain',
    parents: ['root']
  };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
  form.append('file', file);

  let res = await fetch(
    'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true',
    {
      method: 'POST',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: form
    }
  );
  res = await handleFetchResponseErrors(res);

  const fileInstance = await res.json();

  if (!fileInstance.id) {
    console.error(fileInstance);
    throw new Error('unexpected response after creating a file on google drive');
  }
  return fileInstance.id;
}

/**
 *
 */
async function loadStorageFileContent(accessToken: string, fileId: string): Promise<any> {
  let res = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, {
    method: 'GET',
    headers: new Headers({Authorization: 'Bearer ' + accessToken})
  });
  res = await handleFetchResponseErrors(res);

  const fileContent = await res.text();
  try {
    return JSON.parse(fileContent);
  } catch (err) {
    throw new Error('Could not parse file response to json! ' + err);
  }
}

/**
 * https://developers.google.com/drive/api/v3/reference/files/update
 */
async function saveStorageFile(accessToken: string, fileContent: string) {
  const fileId = await getOrCreateStorageFile(accessToken);
  const file = new Blob([fileContent], {type: 'text/plain'});
  const metadata = {
    name: STORAGE_FILE_NAME,
    mimeType: 'text/plain'
  };

  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
  form.append('file', file);

  let res = await fetch(
    `https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&supportsAllDrives=true`,
    {
      method: 'PATCH',
      headers: new Headers({Authorization: 'Bearer ' + accessToken}),
      body: form
    }
  );
  res = await handleFetchResponseErrors(res);
  return res.json();
}
