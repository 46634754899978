import styled, {createGlobalStyle} from 'styled-components';

import {FONT_GREY, FONT_LIGHT_BORDER_GREY, HIGHLIGHTING, PRIMARY} from './colors';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: Candara, Calibri, Segoe, Segoe UI, Optima, Arial, sans-serif;
  }

  a, a:link, a:visited {
    color: rgba(97, 155, 242, 1);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  #root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .react-dropdown-select-content {
    flex-wrap: nowrap !important;
    width: 250px;
  }

  .react-dropdown-select-content > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    flex-grow: 0;
  }


  //  customize browser scrollbars
  body::-webkit-scrollbar,
  body ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
    background: transparent;
  }

  body::-webkit-scrollbar-button:vertical:end:decrement,
  body ::-webkit-scrollbar-button:vertical:end:decrement,
  body::-webkit-scrollbar-button:vertical:start:increment,
  body ::-webkit-scrollbar-button:vertical:start:increment,
  body::-webkit-scrollbar-button:horizontal:end:decrement,
  body ::-webkit-scrollbar-button:horizontal:end:decrement,
  body::-webkit-scrollbar-button:horizontal:start:increment,
  body ::-webkit-scrollbar-button:horizontal:start:increment {
    display: none;
  }

  body::-webkit-scrollbar-thumb:vertical,
  body ::-webkit-scrollbar-thumb:vertical {
    box-shadow: inset 3px 0 #fff;
    background: transparent;
  }


  body::-webkit-scrollbar-thumb:horizontal,
  body ::-webkit-scrollbar-thumb:horizontal {
    box-shadow: inset 3px 0 #fff;
    background: transparent;
  }

  body::-webkit-scrollbar-thumb:vertical:hover,
  body ::-webkit-scrollbar-thumb:vertical:hover {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const StyledApp = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 100%;
`;

export const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  resize: vertical;
  width: 100%;
  display: block;
  border: 1px solid ${FONT_LIGHT_BORDER_GREY};
  padding: 4px;
  background: transparent;
  outline: none;

  margin-bottom: 8px;
  margin-top: 8px;
  min-height: 200px;

  &:hover {
    border: 1px solid ${FONT_GREY};
  }

  &:active,
  &:focus {
    border: 1px solid ${HIGHLIGHTING};
  }
`;

export const StyledSearchField = styled.div`
  background: white;
  box-shadow: -6px 1px 9px 0px rgb(0 0 0 / 9%);
  width: 324px;

  margin-right: 24px;
`;

export const StyledOptionsBar = styled.div`
  bottom: 32px;
  background: transparent;
  position: absolute;
  right: 560px;
  z-index: 30000;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-left: 4px;
  }
`;

export const StyledShadowedPill = styled.div`
  display: flex;
  flex-direction: row;

  border: 1px solid ${FONT_LIGHT_BORDER_GREY};
  border-radius: 24px;
  padding: 6px 8px;
  background: white;
  box-shadow: -6px 1px 9px 0 rgb(0 0 0 / 9%);
`;

export const StyledStreamsFilter = styled(StyledShadowedPill)`
  padding: 4px;
`;

interface StyledStreamToggleButtonProps {
  $selected: boolean;
  $color: string;
}

export const StyledStreamToggleButton = styled.div<StyledStreamToggleButtonProps>`
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => (props.$selected ? props.$color : 'white')};
    border: 2px solid ${(props) => (props.$selected ? 'transparent' : props.$color)};
    display: block;
    box-sizing: border-box;
  }
`;

export const StyledCenter = styled.div`
  justify-self: center;
  align-self: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledTopScreenWarning = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vh;
  z-index: 123;

  > div {
    box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 48%);
    font-size: large;
    margin: 16px 0;
    padding: 8px;
    border: 1px solid #bf0606;
    border-radius: 8px;
    background: rgb(244, 166, 128);
    color: #bf0606;
  }
`;
