/**
 *
 * @param response
 */
export async function handleFetchResponseErrors(response: Response) {
  if (response.ok) {
    return response;
  }

  // if not "ok",  print body and throw Error
  if (response.json) {
    const body = await response.json();
    console.error(body);
  }
  throw new Error(
    `${response.status} : ${response.statusText} : // TODO: response?.error?.message`
  );
}
